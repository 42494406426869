import React from "react";

const Videos = () => {
    return (
        <section className="section-videos">
            <h1>Videos</h1>

            <div className="contenedor-videos">
                <div className="video-box">
                    <p>Razón y Corazón</p>
                    <iframe
                        width="500"
                        height="315"
                        src="https://www.youtube.com/embed/UzE85xhDwQg?si=cgwpCd4hlRqd9ZX9"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
                <div className="video-box">
                    <p>Viejos Lazos</p>
                    <iframe
                        width="500"
                        height="315"
                        src="https://www.youtube.com/embed/tyOpdKsBUFw?si=vUjoHq2eoyY2tRpC"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
                <div className="video-box">
                    <p>Cegado</p>
                    <iframe
                        width="500"
                        height="315"
                        src="https://www.youtube.com/embed/N-m7xCIN_3w"
                        title="YouTube video player"
                        style={{ border: "0px" }}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                ></iframe>
                </div>
                <div className="video-box">
                    <p>Sesión en Vivo</p>
                    <iframe
                        width="500"
                        height="315"
                        src="https://www.youtube.com/embed/RwWWIFmnKf8"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
                <div className="video-box">
                    <p>Mini Documental</p>
                    <iframe
                        width="500"
                        height="315"
                        src="https://www.youtube.com/embed/NniNfrUpF6I"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
            </div>
        </section>
    );
};

export default Videos;
